<template>
  <div class="amenities-add animatedBox">
    <div class="container fluid">
      <form @submit.prevent="submitForm">
        <div class="card mb-2 pb-1">
          <h4 class="mainTitle lineBottom">Amenity Content</h4>
          <div class="main-contents">
            <fd-input
              class="mt-2"
              type="text"
              :label="`Amenity Name`"
              v-model="amenity.name"
              :required="true"
            />
            <fd-textarea
              class="mt-2"
              :label="`Amenity Description`"
              v-model="amenity.description"
              :rows="8"
            />
          </div>
          <div class="text-right px-2 mt-4 mb-2">
            <button type="submit" class="btn main">Create</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { AmenityModel } from "@/models";

export default {
  name: "amenities-add",
  data() {
    return {
      amenity: {
        name: "",
        description: ""
      }
    };
  },
  methods: {
    async submitForm() {
      this.$store.commit("setIsLoading", true, { root: true });
      try {
        const request = await this.$store.dispatch(
          "manageAmenities/createAmenities",
          AmenityModel.postAmenityPayload(this.amenity)
        );

        alert("New amenity created successfully.");
        this.$router.push({ path: "/manage-amenities" });
        this.$store.commit("setIsLoading", false, { root: true });
        return;
      } catch (error) {
        this.$store.commit("setIsLoading", false, { root: true });
        alert("An unexpected error occured. Please try again later.");
        console.log(error);
        throw error;
      }
    }
  }
};
</script>

<style lang="scss">
.amenities-add {
  form {
    @extend %formDesign;
  }
}
</style>